import React, { useState } from "react";
import { Container, List, Segment, Image, Icon } from 'semantic-ui-react';
import { useViewport } from "@nnsa/hooks";
import './Footer.less';

const Footer = () => {
    const { isMobile } = useViewport();
    const [isAboutCollapsed, setIsAboutCollapsed] = useState(true);
    const [isSecondAboutCollapsed, setIsSecondAboutCollapsed] = useState(true);
    const [isNavtejCollapsed, setIsNavtejCollapsed] = useState(true);
    const [isAdditionalResourcesCollapsed, setIsAdditionalResourcesCollapsed] = useState(true);
    const [isNewColumnCollapsed, setIsNewColumnCollapsed] = useState(true);

    const toggleAboutCollapse = () => {
        setIsAboutCollapsed(!isAboutCollapsed);
    };

    const toggleSecondAboutCollapse = () => {
        setIsSecondAboutCollapsed(!isSecondAboutCollapsed);
    };

    const toggleNavtejCollapse = () => {
        setIsNavtejCollapsed(!isNavtejCollapsed);
    };

    const toggleAdditionalResourcesCollapse = () => {
        setIsAdditionalResourcesCollapsed(!isAdditionalResourcesCollapsed);
    };
    const toggleNewColumnCollapse = () => {
        setIsNewColumnCollapsed(!isNewColumnCollapsed);
    };

    const mobileFooterHeader = {
        color: "white",
        fontSize: 14,
        padding: 10,
        marginRight: 25,
        fontWeight: "bold",
    };

    const mobileFooterItems = {
        color: "white",
        fontSize: 14,
        padding: 10,
        marginLeft: 15,
    };

    const mobileFooterContactDetails = {
        color: "white",
        fontSize: 14,
        marginLeft: 10,
        display: "flex",
        flexDirection: "column",
    };

    const mobileFooterContactDetailsFirst = {
        color: "white",
        fontSize: 14,
        marginTop: 10,
        marginLeft: 10,
        display: "flex",
        flexDirection: "column",
    };

    const mobileFooterSegment2 = {
        background: "#242424",
        color: "white",
    };

    const MobileView = () => (
        <>
            <div id="footer-mobile" style={{ background: "#242424", padding: "13px" }}>
                <List.Item>
                    <List.Header>
                        <a href="https://www.energy.gov/">
                            <Image src='/doe-logo-energy-1.png' />
                        </a>
                    </List.Header>
                    <List.Content>
                        <List.Item className="doeaddress">
                            <span style={mobileFooterContactDetailsFirst}>1000 Independence Ave. SW</span>
                            <span style={mobileFooterContactDetails}>Washington DC 20585</span>
                            <span style={mobileFooterContactDetails}>202-586-5000</span>
                        </List.Item>
                        <List.Item style={mobileFooterHeader}>
                            <Icon name='mail' size='large' style={{ color: "white" }} />
                            <a href="/contact-us" target="_blank" style={{ color: "white", textDecoration: "none" }}>Sign Up for Email Updates</a>
                        </List.Item>
                    </List.Content>
                </List.Item>

                <List.Item>
                    <List.Header onClick={toggleAboutCollapse} id="DropDown" style={mobileFooterHeader}>
                        {isAboutCollapsed ? (
                            <Icon name='plus' size='large' style={{ color: "white !important" }} />
                        ) : (
                            <Icon name="minus" size='large' style={{ color: "white !important" }} />
                        )}
                        Federal Government
                    </List.Header>

                    {!isAboutCollapsed && (
                        <List.Content style={mobileFooterItems}>

                            <List.Item><a href="https://www.energy.gov/lm/doe-history" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>The White House</a></List.Item>
                            <List.Item><a href="" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>DOE.gov</a></List.Item>
                            <List.Item><a href="" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>USA.gov</a></List.Item>



                            {/* <List.Item id="DropDownItems">History</List.Item>
                            <List.Item id="DropDownItems" style={{ color: "white" }}>Leadership</List.Item>
                            <List.Item id="DropDownItems" style={{ color: "white" }}>News</List.Item>
                            <List.Item id="DropDownItems" style={{ color: "white" }}>Science Education</List.Item>
                            <List.Item id="DropDownItems" style={{ color: "white" }}>Work with Us</List.Item>
                            <List.Item id="DropDownItems" style={{ color: "white" }}>Careers & Internships</List.Item>
                            <List.Item id="DropDownItems" style={{ color: "white" }}>Contact Us</List.Item> */}
                        </List.Content>
                    )}

                    <List.Header onClick={toggleSecondAboutCollapse} id="DropDown" style={mobileFooterHeader}>

                        {isSecondAboutCollapsed ? (
                            <Icon name='plus' size='large' style={{ color: "white !important" }} />
                        ) : (
                            <Icon name="minus" size='large' style={{ color: "white !important" }} />
                        )}
                        RESOURCES
                    </List.Header>
                    {!isSecondAboutCollapsed && (
                        <List.Content style={mobileFooterItems}>

                            <List.Item><a href="https://www.energy.gov/budget-performance" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Budget & Performance</a></List.Item>

                            <List.Item><a href="https://www.energy.gov/ig/office-inspector-general" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Inspector General</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/cio/privacy-program" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Privacy Program</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/nnsa/nnsa-office-civil-rights" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Office of Civil Rights</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/nnsa/freedom-information-act-foia" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>FOIA</a></List.Item>





                            {/* <List.Item>Budget & Performance</List.Item>
                            <List.Item>Directives, Delegations & Requirements</List.Item>
                            <List.Item>FOIA</List.Item>
                            <List.Item>Inspector General</List.Item>
                            <List.Item>Privacy Program</List.Item>
                            <List.Item>Small Business</List.Item>
                            <List.Item>Staff & Contractor Resources</List.Item> */}
                        </List.Content>
                    )}

                    <List.Header onClick={toggleNavtejCollapse} id="DropDown" style={mobileFooterHeader} >
                        {isNavtejCollapsed ? (
                            <Icon name='plus' size='large' style={{ color: "white !important" }} />
                        ) : (
                            <Icon name="minus" size='large' style={{ color: "white !important" }} />
                        )}
                        Sites
                    </List.Header>
                    {!isNavtejCollapsed && (
                        <List.Content style={mobileFooterItems}>
                            {/* <List.Item>The White House</List.Item>
                            <List.Item>USA.gov</List.Item> */}

                            <List.Item><a href="https://www.whitehouse.gov/" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Kansas City National Security Campus</a></List.Item>
                            <List.Item><a href="https://www.usa.gov/" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Lawrence Livermore Laboratory</a></List.Item>
                            <List.Item><a href="https://www.whitehouse.gov/" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Los Alamos National Laboratory</a></List.Item>
                            <List.Item><a href="https://www.usa.gov/" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Nevada National Security Site</a></List.Item>
                            <List.Item><a href="https://www.whitehouse.gov/" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Pantex Plant</a></List.Item>
                            <List.Item><a href="https://www.usa.gov/" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Sandia National Laboratories</a></List.Item>
                            <List.Item><a href="https://www.whitehouse.gov/" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Savannah River Site</a></List.Item>
                            <List.Item><a href="https://www.usa.gov/" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Y-12 National Security Complex</a></List.Item>

                        </List.Content>
                    )}



                    <List.Header onClick={toggleAdditionalResourcesCollapse} id="DropDown" style={mobileFooterHeader}>

                        {isAdditionalResourcesCollapsed ? (
                            <Icon name='plus' size='large' style={{ color: "white !important" }} />
                        ) : (
                            <Icon name="minus" size='large' style={{ color: "white !important" }} />
                        )}
                        ADDITIONAL RESOURCES
                    </List.Header>
                    {!isAdditionalResourcesCollapsed && (
                        <List.Content style={mobileFooterItems}>
                            <List.Item><a href="https://www.energy.gov/web-policies" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Web Policies</a></List.Item>
                            <List.Item><a href="" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Inviting Friends</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/diversity/no-fear-act-data" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>No Fear Act</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/whistleblower-protection-and-nondisclosure-agreements" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Whistleblower Protection</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/diversity/notice-equal-employment-opportunity-eeo-findings-discrimination-harassment-andor" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Notice of EEO Findings of Discrimination</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/cio/department-energy-information-quality-guidelines" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Information Quality</a></List.Item>
                            <List.Item><a href="https://www.energy.gov/open-government" target="_blank" style={{ color: "#fff", textDecoration: "none" }}>Open Gov</a></List.Item>




                        </List.Content>
                    )}

                    <List.Header onClick={toggleNewColumnCollapse} id="DropDown" style={mobileFooterHeader}>
                        {isNewColumnCollapsed ? (
                            <Icon name='plus' size='large' style={{ color: "white !important" }} />
                        ) : (
                            <Icon name="minus" size='large' style={{ color: "white !important" }} />
                        )}
                        Jobs
                    </List.Header>
                    {!isNewColumnCollapsed && (
                        <List.Content style={mobileFooterItems}>
                            {/* Add the content for the new collapsible column here */}
                            <List.Item>Our Jobs</List.Item>
                            <List.Item>Working at NNSA</List.Item>
                            {/* ... */}
                        </List.Content>
                    )}
                </List.Item>

                {/* <Container>
                    <List horizontal style={mobileFooterSegment2}>
                        <List.Item>Web Policies</List.Item>
                        <List.Item>Inviting Friends</List.Item>
                        <List.Item>No Fear Act</List.Item>
                        <List.Item>Whistleblower Protection</List.Item>
                        <List.Item>Notice of EEO Findings of Discrimination</List.Item>
                        <List.Item>Information Quality</List.Item>
                        <List.Item>Open Gov</List.Item>
                    </List>
                </Container> */}
            </div>
        </>

    );

    const DesktopView = () => (
        <div id="footer-wrapper">
            <Segment
                role="contentinfo"
                vertical
                padded
                textAlign="center"
                className="segment-1"
            >
                <Container>
                    <List
                        horizontal
                        className={isMobile ? "footer mobile" : "footer"}
                    >
                        <List.Item>
                            <List.Header>
                                <Image src='/doe-logo-energy-1.png' />
                            </List.Header>
                            <List.Content>
                                <List.Item className="doeaddress">
                                    <span>1000 Independence Ave. SW</span>
                                    <span>Washington DC 20585</span>
                                    <span>202-586-5000</span>
                                </List.Item>
                                <List.Item>
                                    <Icon name='mail' size='large' />
                                    <a href="/contact-us" target="_blank" style={{ color: "white", textDecoration: "none" }}>Sign Up for Email Updates</a>
                                </List.Item>
                            </List.Content>
                        </List.Item>


                        <List.Item>
                            <List.Header>ABOUT ENERGY.GOV</List.Header>
                            <List.Content>
                                <List.Item><a href="https://www.energy.gov/nnsa/about-nnsa" style={{ color: "#fff", textDecoration: "none" }}>History</a></List.Item>
                                <List.Item> <a href="https://www.energy.gov/nnsa/leadership" style={{ color: "#fff", textDecoration: "none" }}>Leadership</a></List.Item>
                                <List.Item> <a href=" https://www.energy.gov/nnsa/listings/nnsa-news" style={{ color: "#fff", textDecoration: "none" }}>News</a></List.Item>

                                <List.Item><a href="https://www.energy.gov/nnsa/working-nnsa" style={{ color: "#fff", textDecoration: "none" }}>Work with Us</a></List.Item>

                                <List.Item><a href="https://www.energy.gov/careers/jobs" style={{ color: "#fff", textDecoration: "none" }}>Careers & Internships</a></List.Item>
                                <List.Item><a href="/contact-us" style={{ color: "#fff", textDecoration: "none" }}>Contact Us</a></List.Item>
                                <List.Item><a href="/sitemap" style={{ color: "#fff", textDecoration: "none" }}>Site Map</a></List.Item>
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Header>ENERGY.GOV RESOURCES</List.Header>
                            <List.Content>


                                <List.Item><a href="https://www.energy.gov/budget-performance" style={{ color: "#fff", textDecoration: "none" }}>Budget & Performance</a></List.Item>
                                <List.Item><a href="https://www.energy.gov/nnsa/freedom-information-act-foia" style={{ color: "#fff", textDecoration: "none" }}>FOIA</a></List.Item>
                                <List.Item><a href="https://www.energy.gov/nnsa/nnsa-office-civil-rights" style={{ color: "#fff", textDecoration: "none" }}>Office of Civil Rights</a></List.Item>

                                <List.Item><a href="https://www.energy.gov/ig/office-inspector-general" style={{ color: "#fff", textDecoration: "none" }}>Inspector General</a></List.Item>
                                <List.Item><a href="https://www.energy.gov/cio/privacy-program" style={{ color: "#fff", textDecoration: "none" }}>Privacy Program</a></List.Item>


                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Header>FEDERAL GOVERNMENT</List.Header>
                            <List.Content>


                                <List.Item><a href="https://www.whitehouse.gov/" style={{ color: "#fff", textDecoration: "none" }}>The White House</a></List.Item>
                                <List.Item><a href="https://www.energy.gov/" style={{ color: "#fff", textDecoration: "none" }}>DOE.gov</a></List.Item>
                                <List.Item><a href="https://www.usa.gov/" style={{ color: "#fff", textDecoration: "none" }}>USA.gov</a></List.Item>


                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Header>SITES</List.Header>
                            <List.Content>


                                <List.Item><a href="https://kcnsc.doe.gov/" style={{ color: "#fff", textDecoration: "none" }}>Kansas City National Security Campus</a></List.Item>
                                <List.Item><a href="https://www.llnl.gov/" style={{ color: "#fff", textDecoration: "none" }}>Lawrence Livermore Laboratory</a></List.Item>
                                <List.Item><a href="https://lanl.gov/" style={{ color: "#fff", textDecoration: "none" }}>Los Alamos National Laboratory</a></List.Item>
                                <List.Item><a href="http://nnss.gov/" style={{ color: "#fff", textDecoration: "none" }}>Nevada National Security Site</a></List.Item>
                                <List.Item><a href="http://pantex.energy.gov/" style={{ color: "#fff", textDecoration: "none" }}>Pantex Plant</a></List.Item>
                                <List.Item><a href="https://sandia.gov/" style={{ color: "#fff", textDecoration: "none" }}>Sandia National Laboratories</a></List.Item>
                                <List.Item><a href="http://www.srs.gov/general/srs-home.html" style={{ color: "#fff", textDecoration: "none" }}>Savannah River Site</a></List.Item>
                                <List.Item><a href="http://www.y12.doe.gov/" style={{ color: "#fff", textDecoration: "none" }}>Y-12 National Security Complex</a></List.Item>

                            </List.Content>
                        </List.Item>

                    </List>
                </Container>
            </Segment>
            <Segment className="segment-2">
                <Container>
                    <List horizontal>
                        <List.Item as="a" href="https://www.energy.gov/web-policies" style={{ color: "#fff", marginRight: "15px" }}>Web Policies</List.Item>
                        <List.Item as="a" href="https://www.energy.gov/vulnerability-disclosure-policy" style={{ color: "#fff", marginRight: "15px" }}>Vulnerability Disclosure Program</List.Item>
                        <List.Item as="a" href="https://www.energy.gov/justice/no-fear-act-data" style={{ color: "#fff", marginRight: "15px" }}>No Fear Act</List.Item>
                        <List.Item as="a" href="https://www.energy.gov/whistleblower-protection-and-nondisclosure-agreements" style={{ color: "#fff", marginRight: "15px" }}>Whistleblower Protection</List.Item>
                        <List.Item as="a" href="https://www.energy.gov/justice/notice-equal-employment-opportunity-eeo-findings-discrimination-harassment-andor" style={{ color: "#fff", marginRight: "15px" }}>Notice of EEO Findings of Discrimination</List.Item>
                        <List.Item as="a" href="https://www.energy.gov/cio/department-energy-information-quality-guidelines" style={{ color: "#fff", marginRight: "15px" }}>Information Quality</List.Item>
                        <List.Item as="a" href="https://www.energy.gov/open-government" style={{ color: "#fff", marginRight: "15px" }}>Open Gov</List.Item>
                    </List>

                </Container>
            </Segment>
        </div>
    );

    return isMobile ? <MobileView /> : <DesktopView />;
};

export default Footer;