/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */

import {
  TypesView,
  SearchSchemaEnhancer,
  ListingTableVariation,
  ListingNewsVariation,
} from "@nnsa/components";

import { CookieConsent } from '@nnsa/components';

import installAdvancedLink from '@plone/volto-slate/editor/plugins/AdvancedLink';
import GoogleAnalytics from './googleAnalytics';

import { setupServerMiddleware } from './middleware';

import redirects from './nnsa_legacy_old_paths.json';

// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

const portals = {
  "domains": [
    {
      "site": "directives",
      "dev_domain": "https://directives.dev.doxcelerate.com",
      "prod_domain": "https://www.directives.doe.gov"
    },
    {
      "site": "standards",
      "dev_domain": "https://standards.dev.doxcelerate.com",
      "prod_domain": "https://www.standards.doe.gov"
    },
    {
      "site": "nnsa",
      "dev_domain": "https://directives.nnsa.dev.doxcelerate.com",
      "prod_domain": "https://directives.nnsa.doe.gov"
    }
  ],
  getSiteURL: function (requested_site_name) {
    const site = this.domains.find((s) => s.site === requested_site_name);
    return process.env.NODE_ENV === "development" ? site['dev_domain'] : site['prod_domain'];
  },
  updateURL: function (url) {
    return url

    const _url = new URL(url);
    const p = {
      "www.directives.doe.gov": "directives",
      "www.standards.doe.gov": "standards",
      "directives.nnsa.doe.gov": "nnsa",
    }
    const site = p[_url.host]

    if (!site) return url

    return this.getSiteURL(site) + _url.pathname;

  }
}

export default function applyConfig(config) {

  SearchSchemaEnhancer(config);

  config.blocks.blocksConfig.listing.variations.push(
    ListingTableVariation,
    ListingNewsVariation
  );

  config.views.contentTypesViews["NNSA.Type.policy"] = TypesView;
  config.views.contentTypesViews["NNSA.Type.delegation"] = TypesView;

  config.settings = {
    ...config.settings,

    serverConfig: {
      ...config.settings.serverConfig,
      extractScripts: {
        ...config.settings.serverConfig.extractScripts,
        errorPages: true,
      },
    },

    isMultilingual: false,
    supportedLanguages: ['en'],
    defaultLanguage: 'en',
    apiExpanders: [
      ...config.settings.apiExpanders,
      {
        match: '',
        GET_CONTENT: ['breadcrumbs', 'actions', 'types', 'navroot'],
      },
      {
        match: '',
        GET_CONTENT: ['navigation'],
        querystring: (config) => ({
          'expand.navigation.depth': config.settings.navDepth,
        }),
      },
    ],
    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: CookieConsent
      },
      {
        match: '',
        component: GoogleAnalytics
      },
    ],
    expressMiddleware: [
      setupServerMiddleware('old-paths-middleware', '*', function (req, res, next) {
        let reqPath = req.url;
        reqPath = reqPath.replace("/at_download/file", "");
        reqPath = reqPath.replace(/\/view$/, "");
        reqPath = reqPath.replace(/\/@@images\/file$/, "");
        reqPath = reqPath.replace(/\/at_download\/file$/, "");


        if (redirects[reqPath]) {
          res.redirect(301, redirects[reqPath]['path'])
        } else {
          next();
        }
      }),
      setupServerMiddleware('path-replacer-middleware', '*', function (req, res, next) {
        const replacements = [
          {
            test: /\/@@images\/.+\.png$/,
            replacer: "/@@images/image"
          },
          {
            test: /\/at_download\/file$/,
            replacer: "/@@images/file"
          }
        ];
        replacements.forEach(({ test, replacer }) => {
          if (test.test(req.url)) {
            req.url = req.url.replace(test, replacer);
          }
        });

        next();
      })
      , ...config.settings?.expressMiddleware || []
    ],
    portals
  };

  installAdvancedLink(config)

  return config;
}
